<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 px-3 grid-margin stretch-card">
        <div class="card">
          <div class="">
            <b-table
              :items="items"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :busy="loading"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              bordered
              striped
              show-empty
            >
              <!-- FOR LOADING ANIMATIONS -->
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>
              <!-- SHOW IF TABLE IS EMPTY -->
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>
              <template v-slot:cell(redacteur)="data">
                <TableResponsable :responsable="data.value"></TableResponsable>
              </template>
              <template v-slot:cell(ref_cadre)="data">
                <div class="text-center">
                  <b-badge
                    variant="primary"
                    @click="$emit('showCard')"
                    pill
                    style="cursor: pointer"
                  >
                    {{ data.value }}
                  </b-badge>
                </div>
              </template>

              <template v-slot:cell(name)="data">
                <b-link
                  :to="{
                    name: 'show-principe-ref',
                    params: {
                      id: data.item.id,
                      slug: data.item.slug,
                    },
                  }"
                  >{{ data.value }}
                </b-link>
              </template>

              <template v-slot:cell(type)="data">
                <span>{{ data.value ? data.value : "-" }} </span>
              </template>

              <template v-slot:cell(fichier_joint)="data">
                <b-link>{{ data.value }}</b-link>
              </template>
              <template v-slot:cell(principe)="data">
                <b-link
                  v-if="data.value"
                  :to="{
                    name: 'show-principe-ref',
                    params: {
                      id: data.value.id || '2',
                      slug: data.value.slug || '621dd50372819',
                    },
                  }"
                  >{{ data.value ? data.value.name : "-" }}
                </b-link>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(capaciteTechnique)="data">
                <div class="text-center">
                  <b-badge
                    variant="primary"
                    pill
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    @click="capacitelist(data.value, data.item.name)"
                    >{{ data.value.length }}
                  </b-badge>
                </div>
              </template>
              <template v-slot:cell(systemesApplication)="data">
                <div class="text-center">
                  <b-badge
                    variant="primary"
                    pill
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    @click="
                      systemeapp(data.value, data.item.name, data.item.id)
                    "
                    >{{ data.value.systemelenght }}
                  </b-badge>
                </div>
              </template>
              <template v-slot:cell(fileCadres)="data">
                <div class="text-center">
                  <b-badge
                    variant="primary"
                    pill
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    @click="display(data.value, data.item.name)"
                    >{{ data.value.length }}
                  </b-badge>
                </div>
              </template>
              <template #cell(actions)="data">
                <table-actions
                  :actions="isAdmin || isSuper || isArchitect ? ['delete'] : []"
                  :editMode="data.item.hasOwnProperty('editMode')"
                  @showItem="
                    $router.push({
                      name: 'show-principe-ref',
                      params: {
                        id: data.item.id,
                        slug: data.item.slug,
                      },
                    })
                  "
                  @editItem="$router.push({ name: 'edit-principe-ref' })"
                  @deleteItem="deleteItem(data.item.id)"
                />
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showfile" to="modals-xyz-548">
      <modal @close="showfile = !showfile">
        <Filelist :items="data" :libelle="libelle" />
      </modal>
    </div>
    <div v-if="showlist" to="modals-xyz-548">
      <modal @close="showlist = !showlist">
        <CapaciteList :items="capacite" :libelle="name" />
      </modal>
    </div>
    <div v-if="showSysteme" to="modals-xyz-548">
      <modal @close="showSysteme = !showSysteme">
        <SystemeList :items="systeme" :libelle="name" :id="id" />
      </modal>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "../../../../components/custom/TableActions.vue";
import Swal from "sweetalert2";
import TableResponsable from "../../../../components/custom/TableResponsable";
import Filelist from "./Filelist.vue";
import CapaciteList from "./CapaciteList.vue";
import SystemeList from "./SystemeList.vue";
import Modal from "@/components/custom/Modal.vue";
import { mapGetters } from "vuex";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    SystemeList,
    TableResponsable,
    CapaciteList,
    TableActions,
    Modal,
    Filelist,
  },
  data: function () {
    return {
      sortBy: "name",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      showfile: false,
      showlist: false,
      name: "",
      id: null,
      capacite: null,
      systeme: null,
      data: null,
      libelle: "",
      showSysteme: false,
      fields: [
        { key: "name", label: "Cadre", sortable: true },
        { key: "type", label: "Type Cadre", sortable: true },
        { key: "principe", label: "Principe appliqués", sortable: true },
        { key: "redacteur", label: "Redacteur", sortable: true },
        { key: "status", label: "Status", sortable: true },
        // {key: "typeSysteme", label: "Types systémes", sortable: true},
        {
          key: "systemesApplication",
          label: "Systémes d'application",
          sortable: true,
        },
        // {key: "application", label: "Application", sortable: true},
        { key: "capaciteTechnique", label: "Capacités", sortable: true },
        { key: "fileCadres", label: "Fichier joint", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
    rows() {
      return this.items.length;
    },
  },
  props: {
    items: Array,
    loading: Boolean,
  },
  methods: {
    display(id, name) {
      this.data = id;
      this.libelle = name;
      if (this.data.length) this.showfile = !this.showfile;
    },
    capacitelist(data, name) {
      if (data.length) {
        this.capacite = data;
        this.name = name;
        this.showlist = !this.showlist;
      }
    },
    systemeapp(item, name, id) {
      if (item.systeme.length > 0) {
        this.systeme = item.systeme;
        this.name = name;
        this.id = id.toString();
        this.showSysteme = !this.showSysteme;
      }
    },
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("cadre/deleteCadre", id).then(() => {
            this.$store.dispatch("cadre/fetchAllCadres");
          });

          Swal.fire("Supprimé!", "", "success");
        }
      });
    },
  },
};
</script>
