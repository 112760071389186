<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="">
            <b-table
              :items="items"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loadingData"
              show-empty
              bordered
              striped
            >
              <!-- FOR LOADING ANIMATIONS -->
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>
              <!-- SHOW IF TABLE IS EMPTY -->
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>
              <template v-slot:cell(cadres)="data">
                <div class="text-center">
                  <b-badge
                    variant="primary"
                    pill
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    @click="cadreList(data.value, data.item.name)"
                    >{{ data.value.length }}
                  </b-badge>
                </div>
              </template>

              <template v-slot:cell(name)="data">
                <b-link
                  :to="{
                    name: 'show-principe',
                    params: {
                      id: data.item.id,
                      slug: data.item.slug || 'abcde',
                    },
                  }"
                >
                  {{ data.value }}</b-link
                >
              </template>

              <template v-slot:cell(createdAt)="data">
                <span>{{
                  moment(data.value, "DD/MM/YYYY").format("DD/MM/YYYY") || "-"
                }}</span>
              </template>

              <template #cell(actions)="data">
                <table-actions
                  :actions="isAdmin || isSuper || isArchitect ? ['delete'] : []"
                  :editMode="data.item.hasOwnProperty('editMode')"
                  @deleteItem="deleteItem(data.item.id)"
                />
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
      <div v-if="showlist" to="modals-xyz-548">
        <modal @close="showlist = !showlist">
          <CadrePrincipe :items="cadre" :libelle="name" />
        </modal>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "../../../../components/custom/TableActions.vue";
import Swal from "sweetalert2";
import Modal from "@/components/custom/Modal.vue";
import CadrePrincipe from "./CadrePrincipe.vue";
import { mapGetters } from "vuex";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: { TableActions, CadrePrincipe, Modal },
  data: function () {
    return {
      sortBy: "name",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      cadre: null,
      name: null,
      showlist: false,
      sortable: true,
      fields: [
        { key: "name", label: "Principe", sortable: true },
        { key: "code", label: "Code principe", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "createdAt", label: "Date création", sortable: true },
        { key: "cadres", label: "Ref cadre", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
    rows() {
      return this.items.length;
    },
  },
  props: {
    items: Array,
    loadingData: Boolean,
  },
  methods: {
    cadreList(data, name) {
      if (data.length) {
        this.cadre = data;
        this.name = name;
        this.showlist = !this.showlist;
      }
    },
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("principe/deletePrincipe", id).then(() => {
            this.$store.dispatch("principe/fetchAllPrincipes").then(() => {
              Swal.fire("Supprimé!", "", "success");
            });
          });
        }
      });
    },
  },
};
</script>
