<template>
  <div>
    <section class="section position-relative popUpFather">
      <div class="row">
        <div class="col-12">
          <div class="bg-white p-2">
            <b-tabs
              :value="$store.state.tabs.cadreTab"
              @changed="cadreTab = $store.state.tabs.cadreTab"
              @activate-tab="
                (newTabIndex) =>
                  $store.dispatch('tabs/changeCadreTab', newTabIndex)
              "
              class="tab-solid tab-solid-primary mt-3"
            >
              <b-tab
                title="Principes"
                v-if="
                  isAdmin ||
                  isSuper ||
                  isArchitect ||
                  isManager ||
                  isCollaborator ||
                  isPo
                "
              >
                <div class="">
                  <div
                    class="
                      col-12
                      d-flex
                      flex-wrap
                      justify-content-between
                      flex-wrap
                      mb-2 mb-md-0
                      p-0
                    "
                  >
                    <div class="my-auto mr-2">
                      <h4 class="card-title my-auto">Liste des principes</h4>
                    </div>
                    <div
                      class="
                        d-flex
                        justify-content-end justify-content-center
                        flex-wrap
                        col-sm-12 col-md-auto
                        p-0
                        ml-auto
                      "
                    >
                      <b-form-group
                        class="ml-2 col-sm-12 col-md-auto p-0 mb-0 my-1"
                        label-for="input1"
                      >
                        <b-form-input
                          placeholder="Recherche..."
                          class="m-0 ml-auto"
                          v-model="principeName"
                        ></b-form-input>
                      </b-form-group>

                      <div
                        v-if="isAdmin || isSuper || isArchitect"
                        class="ml-2 my-auto"
                      >
                        <button
                          @click="$router.push({ name: 'add-principe' })"
                          type="button"
                          style="background-color: #198ae3"
                          class="
                            btn
                            my-auto
                            d-flex
                            text-white
                            align-items-center
                          "
                        >
                          <i class="mdi mdi-plus mr-2"></i>
                          Nouveau principe
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr class="mt-0 bg-secondary" />
                  <div class="col-12 p-0">
                    <PrincipeTable
                      @showCard="cadre = !cadre"
                      class="mt-3"
                      :items="filteredPrincipes"
                      :loadingData="loadingDataP"
                    />
                  </div>
                </div>
              </b-tab>

              <b-tab
                title="Cadres"
                v-if="
                  isAdmin ||
                  isSuper ||
                  isArchitect ||
                  isManager ||
                  isCollaborator ||
                  isPo
                "
              >
                <div
                  class="
                    col-12
                    d-flex
                    flex-wrap
                    justify-content-between
                    flex-wrap
                    mb-2 mb-md-0
                    p-0
                  "
                >
                  <div class="my-auto mr-2">
                    <h4 class="card-title my-auto">Liste des cadres</h4>
                  </div>
                  <div
                    class="
                      d-flex
                      justify-content-end justify-content-center
                      flex-wrap
                      col-sm-12 col-md-auto
                      p-0
                      ml-auto
                    "
                  >
                    <b-form-group
                      class="ml-2 col-sm-12 col-md-auto p-0 mb-0 my-1"
                      label-for="input1"
                    >
                      <b-form-input
                        style="width: 300px"
                        placeholder="Recherche..."
                        v-model="Cadrs"
                        class="m-0 ml-auto"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div
                    v-if="isAdmin || isSuper || isArchitect"
                    class="ml-2 my-auto"
                  >
                    <button
                      @click="$router.push({ name: 'add-principe-ref' })"
                      type="button"
                      style="background-color: #198ae3"
                      class="btn my-auto d-flex text-white align-items-center"
                    >
                      <i class="mdi mdi-plus mr-2"></i>
                      Nouveau cadre
                    </button>
                  </div>
                </div>
                <hr class="m-0 bg-secondary" />
                <div class="row">
                  <div class="col-12">
                    <!-- {{ filteredCapacites }} -->
                    <RefCardsTable
                      @showCard="cadre = !cadre"
                      class="mt-3"
                      :fields="refCardData.tableFields"
                      :items="filteredCapacites"
                      :loading="loading"
                    />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </section>

    <div v-if="showGeoMap" to="modals-xyz-548">
      <Modal @close="showGeoMap = !showGeoMap">
        <!-- <GeoMap :countries="selectedCountries" /> -->
      </Modal>
    </div>
  </div>
</template>

<script>
import PrincipeTable from "./components/PrincipeTable.vue";
import RefCardsTable from "./components/RefCardsTable.vue";
import Modal from "@/components/custom/Modal.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    RefCardsTable,
    PrincipeTable,
    Modal,
  },
  data: () => ({
    showGeoMap: false,
    selectedCountries: null,
    loadingDataP: true,
    showGraf: 0,
    Cadrs: "",
    cadre: false,
    principeName: "",
    loading: true,
    tableItems: [
      {
        libelle: "principe 2",
        code: 2,
        description: "principe 2",
        date_creation: "21/08/2022",
        ref_cadre: 1,
        // actions: ["edit", "delete", "show"],
        actions: ["edit", "delete"],
      },
    ],

    refCardData: {
      tableItems: [
        {
          libelle: "Libelle Pattern",
          type_ref: "Patten",
          principe: 0,
          redacteur: "Karly Vaquaud",
          status: "validé",
          type_sys: "",
          sys_app: "",
          type: "type 1",
          application: "application 1",
          contributeurs: "Architecte",
          capa_tech: "cap technique 1-2-1",
          fichier_joint: "ggggfggg",
          actions: ["edit", "delete", "show"],
        },
      ],
    },
  }),

  methods: {
    handleGeoMap(countries) {
      this.selectedCountries = countries;
      this.showGeoMap = !this.showGeoMap;
    },
  },
  created() {
    this.$store.dispatch("cadre/fetchAllCadres").then(() => {
      this.loading = false;
    });

    this.$store.dispatch("principe/fetchAllPrincipes").then(() => {
      this.loadingDataP = false;
    });
  },
  computed: {
    ...mapGetters("cadre", ["CADRE_ENTREPRISES"]),
    ...mapGetters("principe", ["PRINCIPES"]),
    ...mapGetters([
      "isAdmin",
      "isSuper",
      "isArchitect",
      "isManager",
      "isCollaborator",
      "isPo",
    ]),
    filteredCapacites() {
      return this.CADRE_ENTREPRISES.filter(
        (cadrs) =>
          cadrs.name.toLowerCase().indexOf(this.Cadrs.toLowerCase()) > -1
      ).map((data) => ({
        ...data,
        systemesApplication: {
          systemelenght: data.applications.length + data.composants.length,
          systeme: data.applications.map((data) => ({
            ...data,
            type: "Application",
            systeme: data.name,
            capacite: data.capaciteSis
              ? data.capaciteSis.length == 1
                ? data.capaciteSis[0]
                : data.capaciteSis
                    .filter((data) => data.parent)
                    .map((data) => data.parent)[0]
              : "-",
          })),
        },
        capaciteTechnique: data.capacites.map((data) => ({
          ...data,
          type: "Technique",
          name: data.name,
          niveau: data.niveau ? data.niveau : "-",
        })),
      }));
    },
    filteredPrincipes() {
      return this.PRINCIPES.filter(
        (principe) =>
          principe.name.toLowerCase().indexOf(this.principeName.toLowerCase()) >
          -1
      );
    },
  },
};
</script>

<style></style>
